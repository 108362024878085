import React from 'react'

import { Select } from 'frontend/components'

import { selectStyles } from '../selectStyles'
import { Label, Wrapper } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Document Type'
const SELECT_PLACEHOLDER = 'Document Type'
const FIELD_NAME = 'document_type'

const MOCK_DATA = [
  {
    label: 'Medical',
    value: 'medical',
  },
  {
    label: 'Pharmacy',
    value: 'pharmacy',
  },
  {
    label: 'Drug List',
    value: 'drug_list',
  },
  {
    label: 'Formulary',
    value: 'formulary',
  },
  {
    label: 'Individual Criteria',
    value: 'individual_criteria',
  },
  {
    label: 'PA Form',
    value: 'pa_form',
  },
]

export const DocumentType = ({ onChange }: Props) => {
  const handleChange = ({ value }: any) => {
    onChange({
      name: FIELD_NAME,
      value,
    })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select
        options={MOCK_DATA}
        styles={selectStyles}
        placeholder={SELECT_PLACEHOLDER}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
