import React from 'react'

import { Label, Wrapper, Value } from './shared'

interface Props {
  fieldState: any
}

const LABEL_TEXT = 'Book'

export const Book = ({ fieldState }: Props) => {
  const {
    book: { name: bookName },
  } = fieldState

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Value>{bookName}</Value>
    </Wrapper>
  )
}
