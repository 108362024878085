export const formatOptions = (
  data: any,
  isLoading: Boolean,
  labelField: string
) => {
  if (isLoading) return []

  const options = data.reduce(
    (acc: any[], { id, [labelField]: label }: any) => {
      acc.push({ label, value: id })
      return acc
    },
    []
  )

  return options
}
