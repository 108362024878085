import React, { useState } from 'react'
import styled from '@emotion/styled'

import PeoplePowerSelect from 'frontend/components/BoPowerSelect/PeoplePowerSelect'
import { ErrorDialog } from 'frontend/components/Dialog'

import { useExpandOnClick } from './UpdateModal/utils'

import { EditableDataTable, Header } from 'frontend/components'
import { useFilterConfigFeatureFlags } from 'frontend/hooks'
import { FontSpace, Spacing } from 'frontend/utils/'

import { useAddDeleteSearchString, formatTableData } from './utils'
import CreateProviderKdmButton from './CreateProviderKdmButton'
import useProviderKdmData from './useProviderKdmData'
import getColConfigs from './getColConfigs'
import DeleteDialog from './DeleteDialog'
import PushToStagingButton from './PushToStagingButton'
import CreateModal from './CreateModal'
import { UpdateModal } from './UpdateModal'

const HEADER = 'Key Decision Makers'

const ICON_BAR_WIDTH = 60
const ORION_SIDEBAR_WIDTH = 300
const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: `calc(100vw - ${ICON_BAR_WIDTH + ORION_SIDEBAR_WIDTH}px)`,
})

const TitleSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: Spacing.S7,
})

const KeyDecisionMakers = () => {
  const [errorMessage, setErrorMessage] = useState()
  const onExpandClick = useExpandOnClick()
  const addDeleteSearchString = useAddDeleteSearchString()
  const filterConfigFeatureFlags = useFilterConfigFeatureFlags()

  const providerKdmsData = useProviderKdmData({ isPaginated: true })
  const { providers } = providerKdmsData

  const { colConfig, exportConfig } = getColConfigs(
    providerKdmsData,
    addDeleteSearchString,
    onExpandClick
  )

  const filteredColConfig = filterConfigFeatureFlags(colConfig)
  const data = formatTableData(providerKdmsData.providerKeyDecisionMakers.data)
  const isLoading = providerKdmsData.providerKeyDecisionMakers.isLoading
  const utilityBarNodes = [<CreateProviderKdmButton />, <PushToStagingButton />]

  return (
    <Container>
      <CreateModal setErrorMessage={setErrorMessage} />
      <UpdateModal
        setErrorMessage={setErrorMessage}
        providersResponse={providers}
      />
      <ErrorDialog
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <DeleteDialog />
      <TitleSection>
        <Header header={HEADER} headerStyle={{ ...FontSpace.FS5 }} />
        <PeoplePowerSelect />
      </TitleSection>
      <EditableDataTable
        tableId={'provider-kdm-connections-table'}
        hasReset
        hasRowCount
        isLoading={isLoading}
        defaultColWidths={{ minWidth: 20, width: 200 }}
        colConfig={filteredColConfig}
        exportConfig={exportConfig}
        data={data}
        excelLabel={'Provider Key Decision Makers'}
        utilityBarNodes={utilityBarNodes}
      />
    </Container>
  )
}

export default KeyDecisionMakers
