import React from 'react'
import styled from '@emotion/styled'

import { HeaderTabs } from './HeaderTabs'
import { ModalControls } from './ModalControls'
import { HeaderLabel } from './HeaderLabel'

interface PolicyModalHeaderProps {
  closeModal: () => void
}

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 12px',
  width: '100%',
})

const PolicyModalHeader = ({ closeModal }: PolicyModalHeaderProps) => {
  return (
    <Container>
      <HeaderTabs />
      <HeaderLabel />
      <ModalControls closeModal={closeModal} />
    </Container>
  )
}

export default PolicyModalHeader
