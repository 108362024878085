export const formatTableData = (providerKdmData) =>
  providerKdmData.map(
    ({
      person: {
        id,
        first_name,
        last_name,
        npi,
        pathways_organizations,
        twitter_profile,
        twitter_username,
        professional_profile,
        nccn_panels,
      },
      provider: { name, slug },
      ...rest
    }) => ({
      person_id: id,
      person_first_name: first_name,
      person_last_name: last_name,
      person_npi: npi,
      person_nccn_panels: nccn_panels,
      person_twitter_profile: twitter_profile,
      person_twitter_username: twitter_username,
      person_professional_profile: professional_profile,
      person_pathways_organizations: pathways_organizations,
      provider_organization: name,
      provider_slug: slug,
      ...rest,
    })
  )
