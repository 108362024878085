import _ from 'lodash'

import {
  getEntityIdToEntityMaps,
  injectHydratedData,
} from './utils'

import { UseProviderKdmDataResponses } from './interfaces'

const LOADING_VALUE = '...'

const getHydratedOrStubbedData = (responses: UseProviderKdmDataResponses): any[] => {
  if (!responses.providerKdmsResponse.data) return []

  const maps = getEntityIdToEntityMaps(responses)

  let hydratedOrStubbedData = _.cloneDeep(responses.providerKdmsResponse.data)
  hydratedOrStubbedData.forEach(
    injectHydratedData({ responses, maps, loadingValue: LOADING_VALUE })
  )

  return hydratedOrStubbedData
}

export default getHydratedOrStubbedData
