import { useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import { UseReadConfigInput } from 'frontend/interfaces/CrudHookOptions'

import * as CRUD_UTILS from '../../../api/utils'
import { getEmberUserConfigs } from './getEmberUserConfigs'
import { CREATE_TOAST, UPDATE_TOAST } from './constants'
import { CREATE_USER, UPDATE_USER } from './gql-tags'

interface UseReadEmberUserConfigInput extends UseReadConfigInput {
  client?: string
  team?: string
  user?: string
}

interface UseEmberUserProps {
  deleteOptions?: any
  queryInput?: UseReadEmberUserConfigInput
}

export const useEmberUser = ({
  deleteOptions,
  queryInput,
}: UseEmberUserProps = {}) => {
  const location = useLocation()
  const { clientId: selectedClientId } =
    (location.search && queryString.parse(location.search)) || {}

  const createVariables = { input: { selectedClientId } }

  const { readConfig, deleteConfig } = getEmberUserConfigs(
    deleteOptions,
    queryInput,
    createVariables
  )

  const [create, { loading: isCreating }] = useMutation(CREATE_USER, {
    onCompleted: () => {
      toast.success(CREATE_TOAST.SUCCESS_MESSAGE, CREATE_TOAST.SUCCESS_OPTIONS)
    },
  })
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      toast.success(UPDATE_TOAST.SUCCESS_MESSAGE, UPDATE_TOAST.SUCCESS_OPTIONS)
    },
  })

  const { data: userData, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const usersData = userData ? Object.values(userData)[0] : []

  return {
    data: usersData,
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    isLoading,
  }
}
