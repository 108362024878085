import React from 'react'
import { Box } from '@mui/material'
import { SectionLabel } from './SectionLabel'
import { ScrollableTextInput } from 'frontend/components'

const NOTES_STYLE = {
  marginTop: '24px',
  marginLeft: '12px',
  padding: '24px',
  borderRadius: '6px',
  border: '1px solid #e0e0e0',
  width: '423px',
  height: '187px',
}

const Notes = () => {
  return (
    <Box sx={NOTES_STYLE}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '135px' }}>
        <SectionLabel label="Notes" />
      </Box>
      <ScrollableTextInput />
    </Box>
  )
}

export default Notes
