import _ from 'lodash'

export const formatUpdateInput = (
  providerKdmDatum: any,
  personData: any,
  providersOptions: any,
  isLoading: boolean
) => {
  if (isLoading || !providerKdmDatum) return null
  if (_.isEmpty(providersOptions)) return null
  if (_.isEmpty(personData) || !personData[0]) return null

  const formattedInput = {
    start_date: providerKdmDatum?.start_date,
    end_date: providerKdmDatum?.end_date,
    person: personData[0].full_name,
    provider_organization: getOptions(
      providerKdmDatum,
      providersOptions,
      'provider_organization'
    ),
    id: providerKdmDatum?.id,
  }

  return formattedInput
}

const getOptions = (datum: any, options: any, key: string) => {
  return datum?.[key]
    ? (options.find((option: any) => option.value === datum[key]) || {}).label
    : ''
}
