import styled from '@emotion/styled'
import React from 'react'

const Container = styled.div({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  textAlign: 'center',
})

const StyledSpan = styled.span({
  fontFamily: 'inherit',
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '25px',
  letterSpacing: '-0.20000000298023224px',
})

const Divider = styled.span({
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  margin: '0 8px',
  color: '#D2D5DA',
})

export const HeaderLabel: React.FC = () => {
  //Todo: Replace with actual useHook response data
  const labels = ['Aetna', 'Commercial', 'Medical', 'Policy']

  return (
    <Container>
      {labels.map((label, index) => (
        <React.Fragment key={label}>
          <StyledSpan>{label}</StyledSpan>
          {index < labels.length - 1 && <Divider>|</Divider>}
        </React.Fragment>
      ))}
    </Container>
  )
}
