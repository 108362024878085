import React from 'react'

import { Label, Wrapper, Value } from './shared'

interface Props {
  fieldState: any
}

const LABEL_TEXT = 'Document Type'

export const DocumentType = ({ fieldState }: Props) => {
  const { document_type } = fieldState

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Value>{document_type}</Value>
    </Wrapper>
  )
}
