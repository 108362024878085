import React from 'react'

import { Import } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Select a File'
const style: any = {}

export const FileSelect = ({ onChange }: Props) => {
  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Import name={'file'} style={style} onChange={onChange} />
    </Wrapper>
  )
}
