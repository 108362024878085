import React from 'react'

import { Select } from 'frontend/components'
import { usePayerOrganization } from 'frontend/entity-hooks'

import { formatOptions } from '../../../utils'
import { selectStyles } from '../selectStyles'
import { Label, Wrapper } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Payer'
const SELECT_PLACEHOLDER = 'Payer'
const FIELD_NAME = 'payer'

export const Payer = ({ onChange }: Props) => {
  const { data: payers, isLoading } = usePayerOrganization()
  const handleChange = ({ value }: any) => {
    onChange({
      name: FIELD_NAME,
      value,
    })
  }

  const payerOptions = formatOptions(payers, isLoading, 'name')

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select
        options={payerOptions}
        styles={selectStyles}
        placeholder={SELECT_PLACEHOLDER}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
