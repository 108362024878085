interface InjectPerson {
  kdm: any
  personIdToPerson: any
  arePeopleLoading: boolean
  loadingValue: any
}

const injectPerson = ({
  kdm,
  personIdToPerson,
  arePeopleLoading,
  loadingValue,
}: InjectPerson) => {
  kdm.person = arePeopleLoading
    ? {
        first_name: loadingValue,
        middle_name: loadingValue,
        last_name: loadingValue,
        is_on_external_pathways: loadingValue,
        pathways_organizations: loadingValue,
        professional_profile: loadingValue,
        twitter_profile: loadingValue,
        twitter_username: loadingValue,
        nccn_panels: loadingValue,
        npi: loadingValue,
      }
    : personIdToPerson[kdm.person]
}

export default injectPerson
