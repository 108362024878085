import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import qs from 'qs'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import { DateInput } from 'frontend/components'

import { LastApprovedPolicyLabel } from './LastApprovedPolicyLabel'
import { CustomLink } from '../CustomLink'

const CUSTOM_DATE_INPUT_STYLES = {
  ICON_PROPS: {
    iconColor: '#BBBFC3',
    styles: { width: '14px', height: '14px' },
  },
  INPUT_STYLES: {
    height: '36px',
    width: '376px',
    backgroundColor: '#F1F5FA',
    borderColor: 'none',
    borderWidth: '0px',
  },
}

const LINK_STYLES = {
  ICON: {
    iconColor: '#0668D9',
    width: '16px',
    height: '16px',
    marginRight: '2px',
  },
  LINK: { display: 'flex', alignItems: 'center', fontSize: '14px' },
}

const LAST_APPROVED_POLICY_STYLES = {
  display: 'flex',
  alignItems: 'center',
  gap: '140px',
  marginTop: '8px',
  marginBottom: '8px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
}

const LastApprovedPolicy = () => {
  const location = useLocation()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const currentTab = queryParams.view

  if (currentTab === 'mostRecent') return null

  return (
    <Box sx={{ marginTop: '20px', marginBottom: '32px' }}>
      <Box sx={LAST_APPROVED_POLICY_STYLES}>
        <LastApprovedPolicyLabel />
        <CustomLink
          text="S3 Link"
          iconStyles={LINK_STYLES.ICON}
          linkStyle={LINK_STYLES.LINK}
        />
      </Box>
      <DateInput
        openPickerIcon={CustomSvgIcon}
        customIconProps={CUSTOM_DATE_INPUT_STYLES.ICON_PROPS}
        helperText="Please select a date"
        iconName="ChevronDown"
        customStyles={CUSTOM_DATE_INPUT_STYLES.INPUT_STYLES}
        placeholder="mm/dd/yyyy"
      />
    </Box>
  )
}

export default LastApprovedPolicy
