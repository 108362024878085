import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { transparentize } from 'polished'
import styled from '@emotion/styled'
import qs from 'qs'

import Color from 'frontend/utils/color'
import { Dialog } from 'frontend/components'
import { useRewriteSearchString } from 'frontend/hooks'

import PdfComparisonViewer from './PDFComparisonViewer'
import MostRecentPolicyView from './MostRecentPolicy'
import ModalHeader from './ModalHeader'
import PolicyDetails from './PolicyDetails'

const Container = styled.section({
  display: 'flex',
  height: 'calc(100% - 54px)',
  position: 'relative',
  borderTop: `1px solid ${transparentize(0.9, Color.BLACK)}`,
})

const PolicyViewerModal = () => {
  const location = useLocation()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const initialTab = queryParams.view
  const rewriteSearchString = useRewriteSearchString()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(Boolean(initialTab))
  }, [initialTab])

  const closeModal = () => {
    setShowModal(false)
    rewriteSearchString({
      view: undefined,
    })
  }

  const openModal = () => {
    if (!initialTab) {
      setShowModal(true)
      rewriteSearchString({
        view: 'mostRecent',
      })
    }
  }

  //TODO!: This is a test url and will be removed in the future
  const testPdfUrl =
    'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'

  return (
    <div>
      <button onClick={openModal}>Open Modal</button>
      {showModal && (
        <Dialog
          contentStyle={{ borderRadius: 0 }}
          dialogStyle={{ background: 'none', padding: 'none' }}
        >
          <ModalHeader closeModal={closeModal} />
          <Container>
            <PolicyDetails />
            <MostRecentPolicyView url={testPdfUrl} />
            <PdfComparisonViewer />
          </Container>
        </Dialog>
      )}
    </div>
  )
}

export default PolicyViewerModal
