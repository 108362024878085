import React from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

interface MostRecentPolicyViewProps {
  url: string
}

const MostRecentPolicy = ({ url }: MostRecentPolicyViewProps) => {
  const location = useLocation()
  const queryParams = qs.parse(location.search)
  const activeView = queryParams.view || 'mostRecent'

  if (activeView !== 'mostRecent') return null

  return (
    <iframe
      src={url}
      width={'100%'}
      height={'calc(100% - 54px)'}
      style={{ border: 'none', marginLeft: '48px' }}
      title="PDF Viewer"
    />
  )
}

export default MostRecentPolicy
