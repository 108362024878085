interface InjectProvider {
  kdm: any,
  providerIdToProvider: any,
  areProvidersLoading: boolean,
  loadingValue: any,
}

const injectProvider = ({ kdm, providerIdToProvider, areProvidersLoading, loadingValue }: InjectProvider)  => {
  kdm.provider = areProvidersLoading
    ? { name: loadingValue }
    : providerIdToProvider[kdm.provider_organization]
}

export default injectProvider
