import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { Select } from 'frontend/components'

import { selectStyles } from './selectStyles'

interface Props {
  fieldState: any
}

const TITLE_TEXT = 'Products'
const SELECT_PLACEHOLDER = 'Product(s)'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: 6,
  backgroundColor: Colors.WHITE,
  padding: 24,
  height: 125,
  width: 464,
})

const Title = styled.h1({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 24,
})

export const Products = ({ fieldState }: Props) => {
  const { products } = fieldState

  return (
    <Wrapper>
      <Title>{TITLE_TEXT}</Title>
      <Select
        isMulti
        isDisabled
        defaultValue={products}
        styles={selectStyles}
        placeholder={SELECT_PLACEHOLDER}
      />
    </Wrapper>
  )
}
