import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Tabs } from '@mui/base/Tabs'
import styled from '@emotion/styled'
import qs from 'query-string'

import { useRewriteSearchString } from 'frontend/hooks'

import DocumentCompareIcon from './DocumentCompareIcon'
import SingleDocumentIcon from './SingleDocumentIcon'
import StyledTabsList from './TabList'
import StyledTab from './Tab'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  fontFamily: 'inherit',
})

export const HeaderTabs = () => {
  const location = useLocation()
  const rewriteSearchString = useRewriteSearchString()
  const queryParams = qs.parse(location.search)
  const initialTab =
    typeof queryParams.view === 'string' ? queryParams.view : 'mostRecent'
  const [value, setValue] = useState<string>(initialTab)

  useEffect(() => {
    setValue(initialTab)
  }, [initialTab])

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
    const view = newValue === 'comparison' ? 'comparison' : 'mostRecent'
    rewriteSearchString({ view })
  }

  return (
    <Container>
      <Tabs value={value} onChange={handleChange} aria-label="header tabs">
        <StyledTabsList>
          <StyledTab value="mostRecent">
            <SingleDocumentIcon />
            Most Recent
          </StyledTab>
          <StyledTab value="comparison">
            <DocumentCompareIcon />
            Comparison
          </StyledTab>
        </StyledTabsList>
      </Tabs>
    </Container>
  )
}
