import React from 'react'
import { Link } from '@mui/material'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface CustomLinkProps {
  text: string
  linkStyle: any
  iconStyles: any
}

export const CustomLink = ({
  text,
  linkStyle,
  iconStyles,
}: CustomLinkProps) => {
  const { iconColor, ...styles } = iconStyles
  return (
    <Link href="#" underline="hover" sx={linkStyle}>
      <CustomSvgIcon iconColor={iconColor} iconName="Link" styles={styles} />
      {text}
    </Link>
  )
}
