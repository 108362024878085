import React, { useRef } from 'react'
import styled from '@emotion/styled'

import { useQueryString } from 'frontend/hooks'

import { Content } from './Content'
import { Header } from './Header'

const MOCK_FIELD_STATE = {
  payer: { id: '0d1b12e1-b09b-4ccf-ac56-0f6d9ee60d6b', name: 'Aetna' },
  book: { id: '45ea065f-5a96-4e41-aa91-4127eb6d61de', name: 'Commercial' },
  benefit_type: 'Medical',
  document_type: 'Policy',
  products: [
    {
      label: 'Keytruda',
      value: 'a4845b12-2277-4053-a7c3-07c2ca66b58c',
    },
    {
      label: 'Carvykti',
      value: '661d8625-f7b1-45f0-b3d1-f5e148adcfc0',
    },
  ],
}

const Wrapper = styled.div({
  width: 512,
  height: 601,
})

export const PrefilledPolicy = () => {
  const { importModal: document } = useQueryString()
  const inputRef: any = useRef({ ...MOCK_FIELD_STATE, document })

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
    debugger
  }

  return (
    <Wrapper>
      <Header fieldState={inputRef.current} />
      <Content onChange={setInputRef} fieldState={inputRef.current} />
    </Wrapper>
  )
}
