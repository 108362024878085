import React from 'react'
import { Typography } from '@mui/material'

export const SectionLabel = ({ label }: { label: string }) => {
  return (
    <Typography
      sx={{
        marginBottom: '16px',
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {label}
    </Typography>
  )
}
