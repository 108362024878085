import _ from 'lodash'

import { UseProviderKdmDataResponses } from '../interfaces'

const getEntityIdToEntityMaps = ({ peopleResponse, indicationsResponse, providersResponse, providerKdmOptionsResponse }: UseProviderKdmDataResponses) => {
  const personIdToPerson = _.keyBy(peopleResponse.data?.vegaPeople, 'id')
  const indicationIdToIndication = _.keyBy(indicationsResponse.data?.vegaIndications, 'id')
  const providerIdToProvider = _.keyBy(providersResponse.data?.vegaProviders, 'id')

  const kdmTypeChoiceValueToChoice = _.keyBy(
    providerKdmOptionsResponse.data?.providerKeyDecisionMakersOptions.actions.POST.key_decision_maker_types.child.choices,
    'value'
  )

  return { personIdToPerson, providerIdToProvider, indicationIdToIndication, kdmTypeChoiceValueToChoice }
}

export default getEntityIdToEntityMaps
