import styled from '@emotion/styled'

import { Tab, tabClasses } from '@mui/base/Tab'

const StyledTab = styled(Tab)`
  font-family: 'inherit';
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  background-color: #f1f5fa;
  color: #607282;
  width: 110px;
  height: 30px;
  margin: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #dde9f4;
    cursor: pointer;
  }

  &.${tabClasses.selected} {
    background-color: #ffffff;
    color: #0668d9;

    &:hover {
      background-color: rgba(6, 104, 217, 0.25);
    }
  }
`

export default StyledTab
