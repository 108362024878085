import * as GQL_TAGS from './gql-tags'

export const getBookOfBusinessConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_VEGA_BOOK_OF_BUSINESS,
    readTag: GQL_TAGS.GET_VEGA_BOOKS_OF_BUSINESS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_VEGA_BOOK_OF_BUSINESS,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_VEGA_BOOK_OF_BUSINESS,
    readTag: GQL_TAGS.GET_VEGA_BOOKS_OF_BUSINESS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_VEGA_BOOKS_OF_BUSINESS,
    input: queryInput,
  },
})
