import { CrudHookOptions } from '../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../api/utils'
import { getPayerOrganizationConfigs } from './getPayerOrganizationConfigs'

export const usePayerOrganization = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getPayerOrganizationConfigs(deleteOptions, queryInput)

  const { data, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const payerOrganizationsData = data ? Object.values(data)[0] : []

  return {
    // create,
    // isCreating,
    // update,
    // isUpdating,
    // destroy,
    // isDeleting,
    data: payerOrganizationsData,
    isLoading,
  }
}
