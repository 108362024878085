import React from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

const SingleDocumentIcon = () => {
  const location = useLocation()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const iconColor = queryParams.view === 'mostRecent' ? '#0668D9' : '#9DAEC4'

  const STYLES = {
    width: '18px',
    height: '18px',
    marginRight: '5px',
  }

  return (
    <CustomSvgIcon
      iconName="SingleDocument"
      iconColor={iconColor}
      styles={STYLES}
    />
  )
}

export default SingleDocumentIcon
