import DocumentCompare from './DocumentCompare'
import SingleDocument from './SingleDocument'
import ChevronRight from './ChevronRight'
import ChevronLeft from './ChevronLeft'
import ChevronDown from './ChevronDown'
import Calendar from './Calender'
import Link from './Link'

const iconPaths = {
  DocumentCompare,
  SingleDocument,
  ChevronRight,
  ChevronLeft,
  Calendar,
  ChevronDown,
  Link,
}

export default iconPaths
