import React from 'react'
import { Tag } from '@pulse-analytics/pulse-design-system'
import { transparentize } from 'polished'

export const StatusTag = ({ color, value }) => {
  const background = transparentize(0.85, color)
  return (
    <Tag
      color={color}
      style={{
        border: 'none',
        background: background,
        padding: '2px 8px',
        textTransform: 'none',
      }}
    >
      {value}
    </Tag>
  )
}
