import React from 'react'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'
import { PolarisDialog } from 'frontend/components'

import { NewPolicy, PrefilledPolicy } from './modals'

const QUERY_STRING_TO_POLICY_MODAL: any = {
  newPolicy: <NewPolicy />,
}

const ImportModal = () => {
  const {
    importModal: selectedImportModal,
  }: { importModal?: string } = useQueryString()
  const rewriteSearchString = useRewriteSearchString()

  const defaultModalOnClick = () => {
    rewriteSearchString({
      importModal: 'newPolicy',
    })
  }

  const prefilledModalOnClick = () => {
    rewriteSearchString({
      importModal: 'ca7006ee-026b-46cc-9501-4211579f1421',
    })
  }

  if (!selectedImportModal)
    return (
      <div>
        <button style={{ marginLeft: 20 }} onClick={defaultModalOnClick}>
          Open Default Import Modal
        </button>
        <button style={{ marginLeft: 20 }} onClick={prefilledModalOnClick}>
          Open Prefilled Import Modal
        </button>
      </div>
    )

  const importModal = QUERY_STRING_TO_POLICY_MODAL[selectedImportModal] ?? (
    <PrefilledPolicy />
  )

  return <PolarisDialog>{importModal}</PolarisDialog>
}

export default ImportModal
