import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import iconPaths from './Svgs'

interface CustomSvgIconProps {
  iconName: string
  iconColor?: string
  styles?: React.CSSProperties
}

export const CustomSvgIcon = ({
  iconName,
  iconColor = 'black',
  styles,
}: CustomSvgIconProps) => {
  if (!iconName) return null

  const Icon = iconPaths[iconName as keyof typeof iconPaths]

  if (!Icon) return null

  return (
    <SvgIcon style={{ ...styles }}>
      <Icon iconColor={iconColor} />
    </SvgIcon>
  )
}
