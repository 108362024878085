import React, { useRef, useEffect } from 'react'
import WebViewer from '@pdftron/webviewer'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

const AMS_LICENSE_KEY =
  'The Dedham Group, LLC:ENTERP:Polaris Policy Link Manager::B+:AMS(20240912):A2A534620437F60A0360B13AC9A2537820610FEB99186AF81C627551547C5837128A31F5C7'

const PLACEHOLDER_URL =
  'https://burro-staging.s3.amazonaws.com/source%3Dpayer-policy/placeholder.pdf'

const PdfComparisonViewer = ({ originalUrl, comparisonUrl }) => {
  const viewer = useRef(null)
  const location = useLocation()
  const queryParams = qs.parse(location.search)
  const activeView = queryParams.view

  const urlOne = originalUrl || PLACEHOLDER_URL
  const urlTwo = comparisonUrl || PLACEHOLDER_URL

  useEffect(() => {
    if (activeView !== 'comparison') return
    WebViewer(
      {
        fullAPI: true,
        path: '/webviewer/lib',
      },
      viewer.current
    ).then(async (instance) => {
      const { documentViewer, PDFNet } = instance.Core
      await PDFNet.initialize(AMS_LICENSE_KEY)
      const newDoc = await PDFNet.PDFDoc.create()
      await newDoc.lock()
      const doc1 = await PDFNet.PDFDoc.createFromURL(urlOne)
      const doc2 = await PDFNet.PDFDoc.createFromURL(urlTwo)
      await newDoc.appendTextDiffDoc(doc1, doc2)
      await newDoc.unlock()

      instance.UI.loadDocument(newDoc)

      documentViewer.addEventListener('documentLoaded', () => {
        instance.UI.setLayoutMode(instance.UI.LayoutMode.FacingContinuous)
      })
    })
  }, [urlOne, urlTwo, activeView])

  if (activeView !== 'comparison') return null

  return (
    <div style={{ height: '100%', width: '75%' }}>
      <div style={{ height: '100%' }} className="webviewer" ref={viewer}></div>
    </div>
  )
}

export default PdfComparisonViewer
