import { useMutation } from '@apollo/client'
import { CrudHookOptions } from '../../interfaces/CrudHookOptions'

import * as GQL_TAGS from './gql-tags'

export const usePayerPolicyDocument = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const [create, { loading: isCreating }] = useMutation(
    GQL_TAGS.CREATE_PAYER_POLICY_DOCUMENT
  )

  return {
    create,
    isCreating,
  }
}
