import React from 'react'
import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import { DateInput } from 'frontend/components'

import { DocumentLastLoadDate } from './DocumentLastLoadDate'
import Links from './Links'

const POLICY_LAST_UPDATED = 'Policy Last Updated'

const CUSTOM_DATE_INPUT_STYLES = {
  ICON_PROPS: {
    iconColor: '#BBBFC3',
    styles: { width: '14px', height: '14px' },
  },
  INPUT_STYLES: {
    height: '36px',
    width: '376px',
    backgroundColor: '#F1F5FA',
    borderColor: 'none',
    borderWidth: '0px',
  },
}

const PolicyLastUpdated = () => {
  const location = useLocation()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const currentTab = queryParams.view

  const label =
    currentTab === 'mostRecent'
      ? POLICY_LAST_UPDATED
      : POLICY_LAST_UPDATED + ' (Right)'

  const LABEL_STYLES = {
    color: '#0E2539',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 700,
    marginRight: currentTab === 'mostRecent' ? '60px' : '19px',
  }

  const CONTAINER_STYLE = {
    marginTop: currentTab === 'mostRecent' ? '10px' : '0px',
  }

  return (
    <Box sx={CONTAINER_STYLE}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <Typography sx={LABEL_STYLES}>{label}</Typography>
        <Links />
      </Box>
      <DateInput
        openPickerIcon={CustomSvgIcon}
        customIconProps={CUSTOM_DATE_INPUT_STYLES.ICON_PROPS}
        helperText="Please select a date"
        iconName="Calendar"
        customStyles={CUSTOM_DATE_INPUT_STYLES.INPUT_STYLES}
        placeholder="Select a date"
      />
      <DocumentLastLoadDate />
    </Box>
  )
}

export default PolicyLastUpdated
