import React from 'react'
import { Box, Button } from '@mui/material'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

import PolicyLastUpdated from './PolicyLastUpdated'
import LastApprovedPolicy from './LastApprovedPolicy'
import { SectionLabel } from './SectionLabel'

const BUTTON_STYLE = {
  width: 'Hug (124px)px',
  height: 'Hug (28px)px',
  backgroundColor: '#0668D926',
  color: '#0668D9',
  textTransform: 'none',
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 700,
  marginBottom: '16px',
  letterSpacing: '0.20000000298023224px',
  textAlign: 'center',
  lineHeight: '18px',
  boxShadow: 'none',
}

const DocumentSection = () => {
  const location = useLocation()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const currentTab = queryParams.view

  const height = currentTab === 'mostRecent' ? '188px' : '272'

  return (
    <Box
      sx={{
        marginTop: '24px',
        marginLeft: '12px',
        padding: '24px',
        borderRadius: '8px',
        border: '1px solid #e0e0e0',
        width: '423px',
        height: height,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '135px' }}>
        <SectionLabel label="Documents" />
        <Button variant="contained" sx={BUTTON_STYLE}>
          Import Policy PDF
        </Button>
      </Box>

      <LastApprovedPolicy />
      <PolicyLastUpdated />
    </Box>
  )
}

export default DocumentSection
