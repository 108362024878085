import { GET_VEGA_PEOPLE } from 'frontend/api/queries'

import update from './update'
import onError from './onError'

const getMutationOptions = () => {
  return {
    update,
    onError,
    refetchQueries: [{ query: GET_VEGA_PEOPLE }],
  }
}

export default getMutationOptions
