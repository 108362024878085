import React from 'react'
import { Typography } from '@mui/material'

export const DocumentLastLoadDate = () => {
  //TODO: Replace date with actual data
  return (
    <Typography
      sx={{
        marginTop: '8px',
        color: '#808A96',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: 350,
        lineHeight: '18px',
        letterSpacing: '-0.20000000298023224px',
        textAlign: 'left',
      }}
    >
      Document last load date: 5/13/24
    </Typography>
  )
}
