import React from 'react'
import { CollapsibleSidePanel } from 'frontend/components'
import DocumentsSection from './DocumentsSection'
import Notes from './Notes'

const SIDE_PANEL_WRAPPER_STYLE = {
  paddingBottom: '15px',
  fontSize: 20,
  fontFamily: 'Inter',
  fontWeight: 500,
  paddingRight: '5px',
}

const POLICY_DETAILS_LABEL = 'Policy Details'

const PolicyDetails = () => {
  return (
    <CollapsibleSidePanel
      label={POLICY_DETAILS_LABEL}
      wrapperStyles={SIDE_PANEL_WRAPPER_STYLE}
    >
      <DocumentsSection />
      <Notes />
    </CollapsibleSidePanel>
  )
}

export default PolicyDetails
