import React from 'react'

import { Label, Wrapper, Value } from './shared'

interface Props {
  fieldState: any
}

const LABEL_TEXT = 'Benefit Type'

export const BenefitType = ({ fieldState }: Props) => {
  const { benefit_type } = fieldState

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Value>{benefit_type}</Value>
    </Wrapper>
  )
}
