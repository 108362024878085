import React from 'react'

import { Label, Wrapper, Value } from './shared'

interface Props {
  fieldState: any
}

const LABEL_TEXT = 'Payer'

export const Payer = ({ fieldState }: Props) => {
  const {
    payer: { name: payerName },
  } = fieldState

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Value>{payerName}</Value>
    </Wrapper>
  )
}
