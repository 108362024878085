import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { PolicyDetails } from './PolicyDetails'
import { Products } from './Products'

interface Props {
  onChange: any
  fieldState: any
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: Colors.COOL_GRAY_100,
  padding: 24,
  height: 533,
  width: 512,
})

export const Content = ({ onChange, fieldState }: Props) => {
  return (
    <Wrapper>
      <PolicyDetails onChange={onChange} fieldState={fieldState} />
      <Products fieldState={fieldState} />
    </Wrapper>
  )
}
