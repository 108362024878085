import {
  injectPerson,
  injectProvider,
} from './injections'

const injectHydratedData = ({
  responses,
  maps,
  loadingValue,
}: any) => {
  const {
    peopleResponse: { loading: arePeopleLoading },
    providersResponse: { loading: areProvidersLoading },
  } = responses

  const {
    personIdToPerson,
    providerIdToProvider,
  } = maps

  return (kdm: any) => {
    injectPerson({
      kdm,
      personIdToPerson,
      arePeopleLoading,
      loadingValue,
    })
    injectProvider({
      kdm,
      providerIdToProvider,
      areProvidersLoading,
      loadingValue,
    })
  }
}

export default injectHydratedData
