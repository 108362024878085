import * as GQL_TAGS from './gql-tags'

export const getPayerOrganizationConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_VEGA_PAYER_ORGANIZATION,
    readTag: GQL_TAGS.GET_VEGA_PAYER_ORGANIZATIONS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_VEGA_PAYER_ORGANIZATION,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_VEGA_PAYER_ORGANIZATION,
    readTag: GQL_TAGS.GET_VEGA_PAYER_ORGANIZATIONS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_VEGA_PAYER_ORGANIZATIONS,
    input: queryInput,
  },
})
