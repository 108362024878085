import _ from 'lodash'

import joinData from './joinData'

const getJoinedPathwaysInfluencerData = ({
  pathwaysInfluencersData,
  pathwaysOrganizationData,
  peopleData,
  indicationsData,
}) => {
  const groupedPathwaysOrganizationById = groupDataById(
    pathwaysOrganizationData
  )
  const groupedPeopleById = groupDataById(peopleData)
  const groupedIndicationsById = groupDataById(indicationsData)

  const joinedPathwaysInfluencersData = pathwaysInfluencersData.reduce(
    joinData({
      groupedPathwaysOrganizationById,
      groupedPeopleById,
      groupedIndicationsById,
    }),
    []
  )
  return joinedPathwaysInfluencersData
}

export default getJoinedPathwaysInfluencerData

const groupDataById = (dataObj) => {
  const data = Object.values(dataObj)[0]
  const groupedData = _.keyBy(data, 'id')
  return groupedData
}
