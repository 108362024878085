import React from 'react'
import styled from '@emotion/styled'

import { CustomLink } from '../CustomLink'

const Container = styled.div`
  display: flex;
  margin-left: 12px;
  gap: 12px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
`

const LINK_STYLES = {
  ICON: {
    iconColor: '#0668D9',
    width: '16px',
    height: '16px',
    marginRight: '2px',
  },
  LINK: { display: 'flex', alignItems: 'center', fontSize: '14px' },
}

const Links = () => {
  return (
    <Container>
      <CustomLink
        text="Payer Site"
        iconStyles={LINK_STYLES.ICON}
        linkStyle={LINK_STYLES.LINK}
      />
      <CustomLink
        text="S3 Link"
        iconStyles={LINK_STYLES.ICON}
        linkStyle={LINK_STYLES.LINK}
      />
    </Container>
  )
}

export default Links
